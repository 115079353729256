.nav-container {
  height: 48px;
  border-bottom: 1px solid;
  border-color: rgba(245, 236, 228);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4vw;
}

.progress-container {
  height: 6px;
  background-color: #eee;
}

.progress-progress {
  background: linear-gradient(45deg, #9198e5, #ff62ab);
  width: 40px;
  height: 100%;
}

.progress-container2 {
  height: 6px;
  background-color: #fff;
}

.progress-progress2 {
  background-color: #ffa6b2;
  width: 40px;
  height: 100%;
}

.prep-result-container {
  padding: 24px 24px 24px 24px;
  border: 2px #ddd solid;
  border-radius: 8px;
  margin-bottom: 32px;
}

.prep-result-title-top {
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 16px;
}

.prep-result-title {
  font-size: 14px;
  font-weight: 600;
  color: green;
  margin-bottom: 6px;
}

.prep-result-text {
  font-size: 14px;
  color: #000;
  margin-bottom: 32px;
}


.prep-result-title2 {
  font-size: 14px;
  font-weight: 600;
  color: rgb(171, 7, 7);
  margin-bottom: 6px;
}

.prep-result-text2 {
  font-size: 14px;
  color: #000;

}

.arrow-btn {
  cursor: pointer;
  height: 36px;
  width: 36px;
  border: none;
  background-color: #fff;
  padding: 6px;
  border-radius: 18px;
}

.arrow-btn:hover {
  background-color: #eee;
}

.body-container {
  background-color: #fffbf7;
  padding: 56px 4vw 0px 4vw;
  min-height: calc(100vh - 73px - 49px - 64px);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.body-container2 {
  background-color: #fffaf7;
  padding: 56px 4vw 0px 4vw;
  min-height: calc(100vh - 72px - 6px - 56px);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.body-container-otc {
  background-color: #fffaf7;
  /* padding: 32px 4vw 0px 4vw; */
  min-height: calc(100vh - 72px - 6px - 56px);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.otc-back-container {
  background-color: #fffaf7;
  border-bottom: 1px solid #fee6d9;
  padding: 9px 4vw;
  height: 21px;
  display: flex;
  align-items: center;
}

.page-container {
  width: 475px;
  /* max-width: 600px; */
}

@media (max-width: 600px) {
  /* .body-container2 {
    padding: 56px 6vw 0px 6vw;
  } */
  .page-container {
    width: 100%;
  }
}

.page-container2 {
  width: 400px;
  /* max-width: 600px; */
}

@media (max-width: 450px) {
  .page-container2 {
    width: 100%;
  }
}

.page-title {
  font-family: 'Bossa';
  font-size: 30px;
  line-height: 40px;
  color: #000;
  margin-bottom: 12px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.page-title-fr {
  font-family: 'Poppins';
  font-size: 26px;
  line-height: 40px;
  color: #000;
  margin-bottom: 12px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.otc-title {
  font-family: 'Bossa';
  font-size: 22px;
  line-height: 32px;
  color: #000;
  /* margin-top: -8px; */
  margin-bottom: 16px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  text-align: left;
}

.otc-btn-container{
  display: flex;
  align-items: center;
  margin-top: 24px;
}

@media (max-width: 428px) {
  .otc-title {font-size: 18px;}
}

.otc-social {
  font-size: 11px;
  color: #999;
  display: flex;
  margin-bottom: 16px;
}

.add-cart-item-social {
  width: 12px;
  background-color: #555;
  color: white;
  border: 1px solid white;
  padding: 2px;
  border-radius: 100%;
  margin-left: -8px;
}

.add-cart-item-social-blue {
  width: 12px;
  background-color: rgb(147, 183, 255);
  color: white;
  border: 1px solid white;
  padding: 2px;
  border-radius: 100%;
  margin-left: -8px;
}

.add-cart-item-social-yellow {
  width: 12px;
  background-color: rgb(255, 219, 153);
  color: white;
  border: 1px solid white;
  padding: 2px;
  border-radius: 100%;
  margin-left: -8px;
}

.add-cart-item-social-green {
  width: 12px;
  background-color: rgb(182, 182, 182);
  color: white;
  border: 1px solid white;
  padding: 2px;
  border-radius: 100%;
  margin-left: -8px;
}

.otc-social-bubbles {
  margin-right: 8px;
  margin-left: 8px;
  display: flex;
}

.otc-point {
  display: flex;
  font-size: 14px;
  color: #555;
  text-align: left;
  margin-bottom: 8px;
}

.otc-points {
  margin-bottom: 16px;
}

.otc-point-check {
  min-width: 20px;
  color: rgb(89, 172, 92);
  font-weight: bolder;
  margin-right: 8px;
}


.otc-option-container {
 border: 1px solid rgba(201, 205, 201, 0.4);
 box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.05);
 border-radius: 6px;
 padding: 13px;
 cursor: pointer;
 margin-bottom: 16px;
}

.otc-option-container-active {
  border: 3px solid rgb(89, 172, 92);
  box-shadow: 0px 0px 16px 4px rgba(0,0,0,0.05);
  border-radius: 6px;
  padding: 11px;
  cursor: pointer;
  margin-bottom: 16px;
 }

.most-popular {
  background-color: rgba(226,242,226);
  color: rgb(89, 172, 92);
  border-radius: 3px;
  font-size: 12px;
  padding: 2px 8px;
  font-weight: 500;
  width: fit-content;
  margin-bottom: 8px;
}

.otc-item-name {
  font-size: 14px;
  font-weight: 600;
  margin-top: 6px;
}

.otc-item-price {
  font-size: 14px;
  font-weight: 500;
  color: #999;
  margin-top: 3px;
}

.otc-option-radio {
  border: 2px solid #ccc;
  border-radius: 100%;
  min-width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: 8px;
}

.otc-option-radio-active {
  border: 10px solid rgb(0,157,220);
  border-radius: 100%;
  min-width: 8px;
  height: 8px;
  margin-left: auto;
  margin-right: 8px;
}

.top-wrapper {
  display: flex;
  align-items: center;
}

.otc-item-details {
  font-size: 12px;
  color: rgb(83, 119, 252);
}

.otc-option-container2 {
  display: flex;
  /* align-items: center; */
}

.otc-addon-btn {
  cursor: pointer;
  border: none;
  font-family: 'Poppins';
  padding: 20px 18px;
  border-radius: 50px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-decoration: none;
  color: #e1ff64;
  background-color: #2871f6;
}

.otc-addon-btn:hover {  
  background-color: #222;
}

.otc-addon-btn-sec {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  color: #777;
  text-align: center;
  margin-top: 16px;
}

.page-title-submitted {
  font-family: 'Bossa';
  font-size: 28px;
  line-height: 40px;
  color: #000;
  margin-bottom: 12px;
  margin-top: -12px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.add-cart-otc-img {
  width: 80px;
  margin-right: 12px;
  display: block;
}

.add-cart-otc-img-container {
  width: 80px;
  height: 80px;
  margin-right: 12px;
}


@media (max-width: 400px) {
  .otc-addon-btn {font-size: 14px;}
  .add-cart-otc-img { width: 60px;}
  .add-cart-otc-img-container { 
    width: 60px;
    height: 60px;
  }
  .otc-item-name {
    font-size: 13px;
  }
  .otc-item-price {
    font-size: 13px; 
  }
}

@media (max-width: 600px) {
  .page-title {
    font-size: 20px;
    line-height: 28px;
  }
}

.page-title-sm {
  font-family: 'Bossa';
  font-size: 30px;
  line-height: 40px;
  color: #000;
  margin-bottom: 16px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

@media (max-width: 600px) {
  .page-title-sm {
    font-size: 20px;
    line-height: 28px;
  }
}

.page-title-sm2 {
  font-family: 'Bossa';
  font-size: 30px;
  line-height: 40px;
  color: #000;
  margin-top: 38px;
  margin-bottom: 6px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

@media (max-width: 600px) {
  .page-title-sm2 {
    font-size: 20px;
    line-height: 28px;
  }
}

.page-sub {
  font-weight: 400;
  color: #555;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.page-sub-link {
  font-weight: 400;
  color: #555;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .page-sub {
    font-size: 14px;
    line-height: 20px;
  }
}

.title-container {
  margin-bottom: 32px;
}

.input-label {
  font-family: 'Poppins', sans-serif;
  color: #555;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 24px; */
  margin-bottom: 6px;
}

.input-text {
  font-family: 'Poppins', sans-serif;
  background: #fffaf7;
  border: 2px solid #ddd;
  padding: 16px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
  height: 60px;
  color: #000;
  box-shadow: none;
  appearance: none;
  box-sizing: border-box;
  outline: 0;
}

@media (max-width: 600px) {
  .input-text {
    font-size: 16px;
  }
}

.input-text::placeholder {
  color: #bbb;
}

.input-textarea {
  font-family: 'Poppins', sans-serif;
  background: #fffaf7;
  border: 2px solid #ddd;
  padding: 16px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
  height: 200px;
  color: #000;
  box-shadow: none;
  appearance: none;
  box-sizing: border-box;
  outline: 0;
  resize: none;
  margin: 0;
  display: block;
}

.input-textarea-small {
  background: #fffaf7;
  border: 2px solid #ddd;
  padding: 16px;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
  height: 120px;
  color: #000;
  box-shadow: none;
  appearance: none;
  box-sizing: border-box;
  outline: 0;
  font-family: 'Poppins', sans-serif;
  resize: none;
  margin: 0;
  display: block;
}

.textarea-form {
  margin: 0;
  display: block;
}

.input-space {
  height: 24px;
}

.input-space-12 {
  height: 12px;
}

.input-space-flex {
  width: 16px;
}

.w30 {
  width: calc((60%-16px) / 2);
}

.w40 {
  width: calc(40%-16px);
}

.w50 {
  width: calc((100%-16px) / 2);
}

.w100 {
  width: 100%;
}

.input-button-space {
  height: 16px;
}

.input-error {
  margin-top: 6px;
  font-size: 14px;
  color: red;
}

.input-submit {
  display: none;
}

.input-dob-container {
  display: flex;
}

.input-height-container {
  display: flex;
}

.bottom-section {
  background-color: rgba(245, 236, 228);
  padding: 22px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 11%);
}

.bottom-btn-next {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 21px 22px;
  border-radius: 8px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: black;
}

.bottom-btn-next:disabled {
  background-color: rgb(92, 92, 92);
}

.bottom-btn-next:hover {
  background-color: #222;
}

.bottom-btn-next2 {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 21px 22px;
  border-radius: 50px;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #e1ff64;
  background-color: #2871f6;
}

.bottom-btn-next2:disabled {
  background-color: hsla(219, 92%, 56%, 0.5);
  color: #e1ff64;
  cursor: auto;
}

.bottom-btn-next2[disabled]:hover {
  background-color: hsla(219, 92%, 56%, 0.5);
  color: #e1ff64;
  cursor: auto;
}

.bottom-btn-next2:hover {
  /* background-color: #222; */
  background-color: #e1ff64;
  color: #2871f6;
}

.bottom-btn-next3 {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 21px 22px;
  border-radius: 8px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: black;
}

.bottom-btn-next3:disabled {
  background-color: rgb(92, 92, 92);
}

.bottom-btn-next3:hover {
  background-color: #222;
}

.bottom-btn-back {
  cursor: pointer;
  border: none;
  font-family: 'Poppins', sans-serif;
  padding: 0px 0px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: black;
  margin-right: 32px;
  background-color: transparent;
}

.bottom-btn-back2 {
  font-family: 'Bossa';
  cursor: pointer;
  border: none;
  padding: 0px 0px;
  border-radius: 8px;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: black;
  margin-right: 32px;
  background-color: transparent;
}

.bottom-btn-back-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-btn-back:hover {
  opacity: 0.7;
}

.bottom-btn-back2:hover {
  opacity: 0.7;
}

.bottom-btn-back6 {
  font-family: 'Poppins';
  cursor: pointer;
  border: none;
  padding: 0px 0px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: black;
  margin-right: 8px;
  background-color: transparent;
  height: 21px;
}

.bottom-btn-back6:hover {
  opacity: 0.7;
}

.arrow-back {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}
.arrow-back2 {
  height: 22px;
  width: 22px;
  margin-right: 8px;
}

.arrow-back3 {
  height: 22px;
  width: 22px;
  margin-right: 8px;
}

.bottom-nav {
  margin-top: 32px;
  margin-bottom: 32px;
  /* max-width: 450px; */
  width: 100%;
  display: flex;
}

.bottom-nav2 {
  margin-top: 8px;
  margin-bottom: 8px;
  /* max-width: 450px; */
  width: 100%;
  display: flex;
}

.menu-btn2-contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-later{
  font-size: 13px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  color: #777;
  margin-top: 8px;
}

.upload-later2{
  font-size: 13px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  color: #777;
  margin-top: 20px;
  text-align: center;
}

.button-choice {
  cursor: pointer;
  background-color: #fffaf7;
  border: 2px solid #ddd;
  font-family: 'Poppins', sans-serif;
  padding: 0px 0px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  height: 64px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: black;
  /* margin-right: 32px; */
}

.button-choice:hover {
  background-color: #dfe2fc;
  border: 2px solid #dfe2fc;
  color: black;
}

.button-choice2 {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  background-color: rgb(255, 250, 247);
  border: 2px solid #2871f6;
  padding: 0px 0px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  height: 64px;
  /* letter-spacing: 0.02em; */
  text-decoration: none;
  color: #2871f6;
  /* margin-right: 32px; */
}

.button-choice2:hover {
  background-color: #fff;
  color: #2871f6;
}

.button-choice3 {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  background-color: rgb(255, 250, 247);
  border: 1px solid #2871f6;
  padding: 9px 8px;
  border-radius: 5px;
  width: 100%;
  min-height: 81px;
  text-decoration: none;
  color: #2871f6;
}

.button-choice-active3 {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  background-color: #2871f6;
  border: 1px solid #2871f6;
  padding: 9px 8px;
  border-radius: 5px;
  width: 100%;
  min-height: 81px;
  text-decoration: none;
  color: white;
}

.name-brand-select {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;
  color: #777;
}

.button-choice3:hover {
  background-color: #fff;
  color: #2871f6;
}

.button-choice-sub {
  font-weight: 400;
  font-size: 14px;
}

.button-choice-sub3-1 {
  font-size: 16px;
  font-weight: 700;
}

.name-brand-pricing-msg {
  padding: 16px 16px;
  font-size: 12px;
  font-weight: 500;
  color: #777;
  background-color: #f6e9dd;
  border-radius: 5px;
  margin-bottom: 16px;
  text-align: center;
}

.button-choice-sub3-2 {
  font-size: 12px;
  font-weight: 500;
}

.button-choice-wrap {

  display: flex;
  justify-content: center;
}

.nav-submitted {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.button-choice-tag {
  color: #468847;
  background-color: #dff0d8;
  margin-top: 8px;
  border-radius: 4px;
  font-size: 12px;
  padding: 3px;
  font-weight: 500;
  padding-left: 6px;  
padding-right: 8px;  
  display: flex;
}


.check-size {
  width: 16px;
  margin-right: 6px;

}

.button-choice-sub3-3 {
  font-size: 12px;
  font-weight: 500;
 
}

.button-choice-active {
  cursor: pointer;
  background-color: #9198e5;
  border: 2px solid #9198e5;
  font-family: 'Poppins', sans-serif;
  padding: 0px 0px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  height: 64px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  /* margin-right: 32px; */
}

.button-choice-active2 {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  background-color: #2871f6;
  border: 2px solid #2871f6;
  padding: 0px 0px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  height: 64px;
  text-decoration: none;
  color: white;
}



.button-choice-multi {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  background-color: rgb(255, 250, 247);
  border: 2px solid #2871f6;
  padding: 6px 12px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  min-height: 64px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #2871f6;
  /* margin-right: 32px; */
}

.button-choice-multi:hover {
  background-color: #fff;
  color: #2871f6;
}

.button-choice-multi-active {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  background-color: #2871f6;
  border: 2px solid #2871f6;
  padding: 6px 12px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  min-height: 64px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  /* margin-right: 32px; */
}

@media (max-width: 600px) {
  .button-choice {
    font-size: 14px;
    height: 52px;
  }
  .button-choice-active {
    font-size: 14px;
    height: 52px;
  }
  .button-choice-multi {
    font-size: 14px;
  }
  .button-choice-multi-active {
    font-size: 14px;
  }
  .input-button-space {
    height: 8px;
  }
}

.button-province {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  background-color: rgb(255, 250, 247);
  border: 2px solid #2871f6;
  padding: 0px 0px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  width: calc((100% - 32px) / 3);
  height: 64px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #2871f6;
  box-sizing: border-box;
}

.button-province:hover {
  background-color: #fff;
  color: #2871f6;
}

.button-province-active {
  cursor: pointer;
  background-color: #2871f6;
  border: 2px solid #2871f6;
  font-family: 'Poppins', sans-serif;
  padding: 0px 0px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  width: calc((100% - 32px) / 3);
  height: 64px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  box-sizing: border-box;
}

.province-btn-container {
  width: 450px;
  display: flex;
}

@media (max-width: 600px) {
  .province-btn-container {
    width: 100%;
  }
}

.modal-backdrop {
  background-color: #000000aa;
  height: 100vh;
  width: 100vw;
  position: fixed;
  /* position: absolute; */
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modal-container {
  background-color: white;
  padding: 32px;
  width: 450px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-container1a {
  background-color: white;
  padding: 32px;
  max-width: 450px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 600px) {
  .modal-container1a {
    padding: 16px;
  }
}

@media (max-width: 600px) {
  .modal-container1a {
    font-size: 8px;
  }
}

.modal-title {
  font-family: 'Bossa';
  font-size: 24px;
  line-height: 32px;
  color: #000;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 24px;
}

.modal-title1a {
  font-family: 'Bossa';
  font-size: 24px;
  line-height: 32px;
  color: #000;
  font-weight: 700;
  text-align: center;
  width: calc(100% - 28px);
  margin-left: 14px;
}

@media (max-width: 600px) {
  .modal-title1a {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .modal-container {
    width: 75%;
    padding-left: 24px;
    padding-right: 24px;
  }
  .modal-title {
    width: 75%;
  }
}

.modal-text {
  font-family: 'Poppins', sans-serif;
  color: #555;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
}

.modal-text1a {
  font-family: 'Poppins', sans-serif;
  color: #555;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
}



@media (max-width: 600px) {
  .modal-text1a {
    font-size: 14px;
    line-height: 20px;
  }
}

.modal-container2 {
  background-color: white;
  padding: 32px;
  width: 420px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.modal-title2 {
  font-family: 'Bossa';
  font-size: 24px;
  line-height: 32px;
  color: #000;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 24px;
  text-align: left;
}

@media (max-width: 600px) {
  .modal-container2 {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.modal-text2 {
  font-family: 'Poppins', sans-serif;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
  text-align: left;
}

@media (max-width: 600px) {

  .modal-title2 {
    font-size: 18px;
  }
  .modal-text2 {
    font-size: 13px;
    line-height: 18px;
  }
}

.modal-btns {
  display: flex;
  width: 100%;
}

.modal-first {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 6px;
  align-items: center;
  justify-content: center;
}

.modal-btns-space {
  margin-right: 16px;
}

.modal-btn {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 21px 22px;
  border-radius: 8px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: black;
  transition: all 0.2s ease-in-out;
}

.modal-btn:hover {
  background-color: #333;
}

.modal-btn-x {
  cursor: pointer;
  color: #aaa;
  width: 28px;
  height: 28px;
  border: none;
  margin:0;
  padding: 0;
  border-radius: 4px;
  background-color: #e0e0e0;
  display: block;
}

.modal-btn-x svg {
  display: block;
}
.modal-btn-x:hover {
  background-color: #dfdfdf;
}

/* PHOTO */
/* PHOTO */
/* PHOTO */

.test-login-section {
  background-color: #dae6fa;
  min-height: 34px;
  padding: 6px 4vw;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.test-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vid-wrapper {
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-left: 16px;
  padding: 12px;
  width: 451px;
  max-height: 260px;
  background-color: white;
  border: 1px solid #f5ece4;
}

.vid-wrapper video {
  width: 100%;
  max-height: 260px;
}

.vid-display1 {
  max-width: 100%;
  max-height: 260px;
}

@media (max-width: 600px) {
  .vid-wrapper {
    width: 92%;
  }

  .vid-wrapper video {
    width: 100%;
    height: 100%;
  }
}

.test-section-bottom {
  background-color: #f3e9e9;
  padding: 24px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 11%);
}

.nav-buttons-bottom {
  margin: 36px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nav-buttons-bottom50 {
  width: calc((100% - 16px) / 2);
}

.test-title1 {
  margin: 0;
  margin-bottom: 12px;
}

.test-title2 {
  margin: 0;
  max-width: 480px;
  text-align: center;
  margin-bottom: 24px;
  color: #544742;
}

.test-sub {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  max-width: 475px;
  text-align: left;
  color: #b7a697;
}

.test-sub-container {
  max-width: 475px;
  text-align: left;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.test-switch {
  font-family: 'Poppins', sans-serif;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 700;
  margin-left: 6px;
  text-align: left;
  color: #d14010;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.test-switch:hover {
  opacity: 0.75;
}

.take-photo-btn {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 21px 22px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.take-photo-btn:hover {
  background-color: #333;
}

.take-photo-btn-space {
  width: 16px;
}

.menu-btn {
  cursor: pointer;
  border: none;
  font-family: 'Bossa';
  padding: 18px 22px;
  width: 446px;
  margin-bottom: 16px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
  transition: all 0.2s ease-in-out;
}

@media (max-width: 600px) {
  .menu-btn {
    width: 100%;
    font-size: 14px;
  }
}

.menu-btn:hover {
  background-color: #333;
}

.menu-btn2 {
  cursor: pointer;
  border: none;
  font-family: 'Poppins';
  padding: 18px 22px;
  width: 446px;
  margin-bottom: 16px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
  transition: all 0.2s ease-in-out;
}

@media (max-width: 600px) {
  .menu-btn2 {
    width: 100%;
    font-size: 14px;
  }
}

.menu-btn2:hover {
  background-color: #333;
}

.menu-btn2-desktop {
  cursor: pointer;
  border: none;
  font-family: 'Poppins';
  padding: 18px 22px;
  width: 446px;
  margin-bottom: 16px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
  transition: all 0.2s ease-in-out;
}

@media (max-width: 600px) {
  .menu-btn2-desktop {
    display: none;
  }
}

.menu-btn2-desktop:hover {
  background-color: #333;
}

.menu-btn2-mobile {
  cursor: pointer;
  border: none;
  font-family: 'Poppins';
  padding: 18px 22px;
  width: 446px;
  margin-bottom: 16px;
  border-radius: 8px;
  font-weight: 600;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: white;
  background-color: #000;
  transition: all 0.2s ease-in-out;
}

@media (min-width: 601px) {
  .menu-btn2-mobile {
    display: none;
   
  }
}

.menu-btn2-mobile:hover {
  background-color: #333;
}

.test-upload-btn {
  font-family: 'Poppins', sans-serif;
  background: #fff;
  /* padding: 20px; */
  background-color: transparent;
  border: 2px dashed #dac3e4;
  border-radius: 6px;
  cursor: pointer;
  width: 471px;
  min-height: 282px;
  /* margin-right: auto;
  margin-left: auto; */
  transition: background-color 0.2s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-front {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
  
  color: #777;
  align-self: flex-start;
}
.card-back {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-top: 16px;
  color: #777;
  align-self: flex-start;
}

@media (max-width: 600px) {
  .test-upload-btn {
    width: 100%;
  }
}

.test-upload-btn:hover {
  background-color: #f8f0fc;
}

.test-upload-btn-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #979797;
  position: relative;
}

.input-hidden {
  visibility: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 0px;
  width: 0px;
}

.test-clickable {
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 30px;
  z-index: 10;
}

.test-input-container {
  position: relative;
}

.test-input {
  background: #fffbf7;
  border: 2px solid #dac4b1;
  padding: 30px;
  border-radius: 5px;
  font-size: 28px;
  height: 20px;
  line-height: 20px;
  font-weight: 700;
  /* max-height: 84px; */
  color: #544742;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
}

.vid-black {
  background-color: black;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vid-black2 {
  background-color: black;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  /* z-index: 99; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-upload {
  width: 90%;
  height: 90%;
  padding: 5%;
  display: block;
  /* z-index: 100; */
}

.image-upload-driver {
  display: block;
  width: 160px;
  opacity: 0.4;
}

.image-upload-driver2 {
  display: block;
  width: 120px;
  opacity: 0.4;
}

.image-upload-driver3 {
  display: block;
  width: 150px;
  opacity: 0.4;
}
.image-upload-group1 {
  display: flex;
  align-items: center;
  justify-content: center;

}


.image-upload-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: -12px;
}

.image-upload-text {
  text-align: center;
  margin-bottom: 16px;
}

.check-id-svg {
  height: 20px;
  min-width: 20px;
  margin-right: 6px;
  color: green;
}

.photo-id-requirements {
  color: #777;
  font-size: 12px;
  
}

.photo-id-requirements-title {
  color: #555;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 6px;
}

.photo-id-requirements-container {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.photo-id-requirements-contain {
  padding: 15px 18px 12px 18px;
  border-radius: 6px;
  background-color: #ece1d6bd;
  margin-bottom: 24px;
  width: 410px;
}

.photo-id-requirements-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%
}

.img-ssl {
  width: 100px;
  margin-top: 16px;
  display: block;
  margin-bottom: 32px;
}

.img-ssl-container {
  display: flex;
  align-items: center;
  justify-content: center;
}


/* LOADER */
/* LOADER */
/* LOADER */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* LOADER BTN */
/* LOADER BTN */
/* LOADER BTN */

.lds-ring2-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lds-ring2 {
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
}
.lds-ring2 div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  /* margin: 8px; */
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring2 div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring2 div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring2 div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* LOADER CHECKOUT */
/* LOADER CHECKOUT */
/* LOADER CHECKOUT */


.lds-ring3-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lds-ring3 {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
}
.lds-ring3 div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 42px;
  height: 42px;
  /* margin: 8px; */
  border: 6px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring3 div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring3 div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring3 div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


